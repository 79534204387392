import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../components/pages/DashboardPage.vue'),
    name: 'Dashboard',
    props: {
      icon: 'mdi-monitor-dashboard'
    }
  },
  {
    path: '/participants',
    component: () => import('../components/pages/ChildrenPage.vue'),
    name: 'Teilnehmer',
    props: {
      icon: 'mdi-account-search-outline'
    },
    children: [
      {
        path: 'display/:id',
        component: () => import('../components/pages/parts/DisplayChild.vue')
      },
      {
        path: 'edit/:id',
        name: 'Teilnehmer bearbeiten',
        component: () => import('../components/pages/parts/EditChild.vue')
      },
      {
        path: 'create',
        name: 'Teilnehmer anlegen',
        component: () => import('../components/pages/parts/EditChild.vue')
      }
    ]
  },
  {
    path: '/team',
    component: () => import('../components/pages/TeamPage.vue'),
    name: 'Team',
    props: {
      icon: 'mdi-account-group'
    }
  },
  {
    path: '/camp',
    component: () => import('../components/pages/CampPage.vue'),
    name: 'Camp',
    props: {
      icon: 'mdi-home-group'
    }
  },
  {
    path: '/broadcast',
    component: () => import('../components/pages/BroadcastPage.vue'),
    name: 'Eltern-Mail',
    props: {
      icon: 'mdi-email-check'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // Path doesn't exit
  if (to.matched.length === 0) {
    next({ name: 'Error' });
  } else {
    store.commit('MUT_CHANGE_HEADER', to.name);
    next();
  }
});

export default router;
