<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center" width="100%">
      <v-card class="mx-auto" max-width="800">
        <v-card-title> <b>Anmeldung</b> Team Zuckerstachel </v-card-title>
        <v-card-subtitle>Zugang für Zuckerstachel Mitglieder</v-card-subtitle>

        <v-alert
          v-if="errorMsg.length > 0"
          class="mx-4"
          dense
          prominent
          outlined
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">{{ errorMsg }}</v-col>
            <v-col class="shrink">
              <v-btn
                @click="
                  errorMsg = '';
                  login.user = '';
                  login.pwd = '';
                "
                >Nochmal</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>

        <v-container>
          <v-text-field
            v-model="login.user"
            :counter="50"
            label="Benutzername"
            prepend-icon="mdi-account-arrow-right"
            required
            outlined
            :disabled="errorMsg.length > 0"
          ></v-text-field>
          <v-text-field
            v-model="login.pwd"
            :counter="50"
            label="Passwort"
            prepend-icon="mdi-lock-question"
            required
            outlined
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            :disabled="errorMsg.length > 0"
            @click:append="showPwd = !showPwd"
          ></v-text-field>
        </v-container>
        <v-card-actions>
          <v-btn
            class="mr-4"
            :disabled="
              errorMsg.length > 0 ||
                login.user.length === 0 ||
                login.pwd.length === 0
            "
            @click="logon"
            >Anmelden</v-btn
          >
          <v-spacer />
          <v-btn text color="primary" @click="dialog = true"
            >Passwort vergessen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog" max-width="290" persistent>
        <v-card>
          <v-card-title class="headline">Passwort vergessen</v-card-title>

          <v-card-text>
            Sende einen Passwort-Link an Deine bei uns registrierte
            eMail-Addresse.
          </v-card-text>
          <v-form form="reset">
            <v-text-field
              v-model="email"
              class="mx-4"
              :counter="50"
              label="eMail"
              outlined
              dense
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="gray" text @click="dialog = false">
              Abbrechen
            </v-btn>

            <v-btn color="red" text @click="onResetPassword">
              Passwort-Reset
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn @click.native="snackbar = false">Schliessen</v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>
<script>
const Parse = require('parse');

export default {
  data() {
    return {
      dialog: false,
      email: null,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      showPwd: false,
      login: { user: 'a', pwd: 'a' },
      errorMsg: ''
    };
  },

  methods: {
    logon() {
      this.$store
        .dispatch('ACT_LOGIN', this.login)
        .then(() => {
          this.$router.push({ path: '/' }).catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes(
                'Avoided redundant navigation to current location'
              )
            ) {
              // But print any other errors to the console
              console.err(err);
            }
          });
        })
        .catch(error => {
          switch (error.code) {
            case 101:
              this.errorMsg = 'Der Benutzername oder das Passwort ist falsch!';
              return;
            default:
              this.errorMsg = 'Fehler beim Anmelden!';
          }
        });
    },
    onResetPassword() {
      if (this.email !== null) {
        Parse.User.requestPasswordReset(this.email)
          .then(() => {
            this.snackbarColor = 'green';
            this.snackbarText = 'eMail versendet falls existent';
            this.snackbar = true;
          })
          .catch(error => {
            this.snackbarColor = 'red';
            this.snackbarText = error;
            this.snackbar = true;
          });
        this.dialog = false;
      }
    }
  }
};
</script>
