<template>
  <v-dialog ref="loader" v-model="dialog" persistent max-width="290">
    <v-card class="pa-4">
      <div class="text-center py-4">Manchmal dauert ein bisschen ...</div>
      <div class="my-4 text-center">
        <v-progress-circular indeterminate color="primary" />
        <v-progress-circular indeterminate color="red" />
        <v-progress-circular indeterminate color="purple" />
        <v-progress-circular indeterminate color="green" />
        <v-progress-circular indeterminate color="amber" />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
