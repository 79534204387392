export const MUT_LOGIN = 'MUT_LOGIN';
export const MUT_LOGOUT = 'MUT_LOGOUT';
export const MUT_ISADMIN = 'MUT_ISADMIN';

export const ACT_LOGIN = 'ACT_LOGIN';
export const ACT_LOGOUT = 'ACT_LOGOUT';
export const ACT_ISADMIN = 'ACT_ISADMIN';

export const ACT_GET_TEAMMEMBERS = 'ACT_GET_TEAMMEMBERS';
export const MUT_GET_TEAMMEMBERS = 'MUT_GET_TEAMMEMBERS';
