import Vue from 'vue';
import Vuex from 'vuex';
import {
  ACT_LOGIN,
  ACT_ISADMIN,
  MUT_LOGIN,
  MUT_ISADMIN,
  ACT_LOGOUT,
  MUT_LOGOUT
} from '../storeTypes';

const Parse = require('parse');

Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL;
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID);

Vue.use(Vuex);

const mutations = {
  [MUT_LOGIN](s, currentUser) {
    const state = s;
    state.isAuthenticated = true;
    state.userName = currentUser.get('username');
    state.userName = currentUser.get('firstName');
  },
  [MUT_LOGOUT](s) {
    const state = s;
    state.isAuthenticated = false;
    state.userName = null;
    state.userName = null;
  },
  [MUT_ISADMIN](s, isAdmin) {
    const state = s;
    state.isAdmin = isAdmin;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async [ACT_LOGIN]({ dispatch, commit }, loginData) {
    return new Promise((success, reject) => {
      Parse.User.logIn(loginData.user, loginData.pwd)
        .then(response => {
          commit('MUT_LOGIN', Parse.User.current());
          dispatch('ACT_ISADMIN')
            .then(() => {
              success(response);
            })
            .catch(error => console.log(`Mist: ${error}`));
          // success(response);
        })
        .catch(e => {
          console.log('Error during Login');
          reject(e);
        });
    });
  },

  async [ACT_ISADMIN]({ commit }) {
    const query = await new Parse.Query(Parse.Role)
      .equalTo('users', Parse.User.current())
      .find();
    const admin = query.filter(role => role.get('name') === 'admin');
    commit('MUT_ISADMIN', admin.length === 1);
  },

  async [ACT_LOGOUT]({ commit }) {
    await Parse.User.logOut();
    commit('MUT_LOGOUT');
  }
};

const state = () => ({
  isAuthenticated: false,
  isAdmin: false,
  username: null
});

export default {
  state,
  mutations,
  actions
};
