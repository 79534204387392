<template>
  <v-app>
    <app-navigation v-if="!authRequired" />

    <v-main>
      <login-page v-if="authRequired" />
      <v-container v-else>
        <transition name="slide-fade">
          <router-view />
        </transition>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- <AppFooter /> -->
    </v-footer>

    <info-dialog ref="info" />
    <confirm-dialog ref="confirm" />
    <sweet-loader ref="loader" />
  </v-app>
</template>

<script>
import loginModule from './store/modules/login';
import LoginPage from './components/LoginPage.vue';
import AppNavigation from './components/appFramework/AppNavigation.vue';
// import AppFooter from './components/appFramework/AppFooter.vue';
import InfoDialog from './components/pages/dialogs/InfoDialog.vue';
import ConfirmDialog from './components/pages/dialogs/ConfirmDialog.vue';
import SweetLoader from './components/gadgets/SweetLoader.vue';

export default {
  name: 'App',

  components: {
    // AppFooter,
    AppNavigation,
    LoginPage,
    InfoDialog,
    ConfirmDialog,
    SweetLoader
  },

  data: () => ({}),

  computed: {
    authRequired() {
      return !this.$store.state.loginModule.isAuthenticated;
    }
  },

  created() {
    /* ensure that the store module for login is registered */
    if (!this.$store.hasModule('loginModule')) {
      this.$store.registerModule('loginModule', loginModule);
      // console.log('Module [loginModule] added to store');
    }
  },

  mounted() {
    this.$root.$info = this.$refs.info;
    this.$root.$confirm = this.$refs.confirm;
    this.$root.$loader = this.$refs.loader;
  }
};
</script>
