<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{ $store.state.header }}</v-toolbar-title>
      <v-spacer />
      <v-spacer />
      <v-chip
        v-if="$store.state.loginModule.isAdmin"
        class="ma-2"
        color="red"
        text-color="white"
      >
        Admin
      </v-chip>
      <v-chip v-else class="ma-2" color="green" text-color="white">
        Member
      </v-chip>

      <v-menu bottom left :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="secondary" text v-bind="attrs" v-on="on">
            <v-icon small class="mr-2">
              mdi-menu
            </v-icon>
            {{ $store.state.loginModule.userName }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-title @click="doAction(item.action)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app dark>
      <v-list dense>
        <v-list-item
          v-for="item in this.$router.options.routes"
          :key="item.name"
          :to="item.path"
          active-class="green--text"
        >
          <v-list-item-icon>
            <v-icon v-text="item.props.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    items: [
      {
        title: 'Abmelden',
        action: 'Abmelden'
      }
    ]
  }),

  methods: {
    doAction() {
      this.$store.dispatch('ACT_LOGOUT');
      // delete the team list
      this.$store.state.teamModule.members = [];
    }
  }
};
</script>
