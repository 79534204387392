<template>
  <v-dialog v-model="dialog" max-width="400" @keydown.esc="cancel">
    <v-card>
      <v-card-title color="red">{{ data.title }}</v-card-title>
      <v-card-text>{{ data.msg }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="primary darken-1" text @click.native="cancel">
          Abbrechen
        </v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">
          Okay
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    data: {
      title: '?',
      msg: ''
    },
    resolve: null,
    reject: null
  }),
  methods: {
    open(o) {
      this.dialog = true;
      this.data.title = o.title;
      this.data.msg = o.msg;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
