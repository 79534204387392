import Vue from 'vue';
import Vuex from 'vuex';

const Parse = require('parse');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    header: 'Zuckerstachel 2021',
    repicients: []
  },
  mutations: {
    MUT_CHANGE_HEADER(state, title) {
      this.state.header = title;
    },

    MUT_GET_REPICIENTS(state, data) {
      this.state.repicients = data;
    }
  },
  actions: {
    async ACT_GET_REPICIENTS({ commit }) {
      commit('MUT_GET_REPICIENTS', await Parse.Cloud.run('getRepicientInfo'));
    },
    async ACT_SEND_BROADCAST(_, data) {
      await Parse.Cloud.run('sendBroadCastEmail', { data });
    }
  },
  modules: {}
});
